import React, { useEffect, useState } from "react";

// vendor
import _ from 'lodash';
import ReactDOM from "react-dom";
import uploadcare from "uploadcare-widget";
import { navigate } from "gatsby";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { FiInfo } from "react-icons/fi";
import axios from"axios";

// core
import { constructUploadcareUrl } from "@mattevans-dev/editsquare.core/utils/mockup-utils";

//components
import MockupHeader from "../components/mockup/header";
import MockupContainer from "../components/mockup/mockupContainer";
import { selectedCanvasElementSelector, selectedSceneElementsSelector } from "../utils/atomStore";
import { mockupsLibrary } from "../library/mockupsLibrary.mjs";
import MockupUploads from "../components/mockup/mockupUploads";
import { isBrowser } from "../utils/utils";

function isValidURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

const loadingIcon = <svg style={{ display: 'none' }} id="loadingScreenshotIcon" className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} />
    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
</svg>;

/* UploadCare Screenshot Tab */
const TAB_NAME = "screenshot";
const TabButtonIcon = ({ node, children }) => {
    return ReactDOM.createPortal(
        <svg style={{ display: "none" }}>{children}</svg>,
        node
    );
};

const MockupChooseMedia = () => {
    const [selectedMedia, setSelectedMedia] = useState({ url: null, uuid: null });
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const canvasElements = useRecoilValue(selectedSceneElementsSelector);
    const setCanvasElements = useSetRecoilState(selectedCanvasElementSelector);

    // only run on first component render
    useEffect(() => {
        let canvasElement = null;
        let aspectRatio = '9:16';
        if (Array.isArray(canvasElements) && canvasElements.length == 1) {
            canvasElement = canvasElements[0];
            const uid = canvasElements[0].templateId;
            const template = _.find(mockupsLibrary, {uid: uid});
            setSelectedTemplate(template);
            aspectRatio = template.aspectRatio;
        }
        // set default values to component state
        let defaultUploadArgs = selectedMedia;
        if (canvasElement?.placeholder?.uuid && canvasElement?.placeholder?.url) {
            defaultUploadArgs = { url: canvasElement?.placeholder?.url, uuid: canvasElement?.placeholder?.uuid };
            setSelectedMedia(defaultUploadArgs);
        }
        // set-up uploadcare defaults
        let tab = "file";
        let file = null;
        if (defaultUploadArgs.url) {
            file = uploadcare.fileFrom('uploaded', defaultUploadArgs.url);
            tab = "preview";
        }
        // create screenshot tab
        uploadcare.registerTab(TAB_NAME, ScreenshotTab);
        // create panel
        // TODO: rebuild on state change
        var panel = uploadcare.openPanel(".uploadcare-dom", file, tab, {
            multiple: false,
            inputAcceptTypes: "image/*",
            crop: aspectRatio,
            tabs: `file ${TAB_NAME}`,
            effects: 'crop,mirror,flip,blur,sharp,enhance,grayscale,invert'
        });
        // set selected media on change to preview panel
        panel.progress((tabName) => {
            if (tabName === 'preview') {
                // move crop to top
                // Promise.all(panel.fileColl.__items).then(fileInfo => {
                //     uploadcare.utils.applyCropCoordsToInfo(fileInfo, {}, [fileInfo.width, fileInfo.height], {height: 669, left: 0, top: 0, width: 375});
                // });
                // wait for upload to complete
                panel.fileColl.__items[0].done(fileInfo => {
                    // update crop information
                    const media = { url: fileInfo.cdnUrl, uuid: fileInfo.uuid, originalUrl: fileInfo.originalUrl };
                    setSelectedMedia(media);
                    // hide done button
                    let doneButton = document.getElementsByClassName("uploadcare--preview__done");
                    if (doneButton && doneButton.length == 1) {
                        const doneBtn = doneButton[0];
                        doneBtn.classList.add("invisible");
                    }
                })
            }
        })
        panel.done((result) => {
            result.promise().done(info => {
                const media = { url: info.cdnUrl, uuid: info.uuid, originalUrl: info.originalUrl };
                mediaSubmit(media);
            });
        });
    }, []);

    // handle done btn click handler
    const submitUploadCare = () => {
        let doneButton = document.getElementsByClassName("uploadcare--preview__done");
        if (doneButton && doneButton.length == 1) {
            const doneBtn = doneButton[0];
            doneBtn.click();
        } else {
            mediaSubmit(selectedMedia);
        }
    }

    // add media to project config
    const mediaSubmit = (media) => {
        const template = canvasElements[0];
        const templateUid = template.uid;
        // const url = constructUploadcareUrl(media.url);
        const url = media.url;
        console.log(">>>", media)
        setCanvasElements({
            uid: templateUid,
            placeholder: {
                // url: 'http://ucarecdn.com/e4035ec2-3aec-4c8c-a4f1-12f0b8db0d4e/-/crop/375x869/0%2C-184/-/overlay/9c352ca0-7675-4eb9-b4f6-cae6c0d115ab/100%25x100%25/0%2C0/-/crop/375x669/0%2C92/-/overlay/7cbf205d-dfee-4511-a32f-5da819d1a064/100%25x100%25/bottom/-/preview/',
                url: url,
                previewUrl: media.previewUrl,
                cdnUrl: media.cdnUrl,
                originalUrl: media.originalUrl,
                uuid: media.uuid // TODO: might not need this
            }
        });
        navigate("/editor");
    };

    const ScreenshotTab = (container, button, dialogApi, settings, name) => {
        const buttonNode = button[0];
        buttonNode.title = "Screenshot";
        const handleScreenshotClick = () => {
            const screenshotNotice = document.getElementById('screenshot-notice');
            const loadingIconElem = document.getElementById('loadingScreenshotIcon');
            const generateScreenshotBtn = document.getElementById('btn-generate-screenshot');
            const rawUrl = document.getElementById('screenshot_url').value;
            const url = 'https://' + rawUrl;
            // check if url is not valid
            if (!isValidURL(url) || !rawUrl) {
                screenshotNotice.style.display = 'block';
                return;
            }
            // display logic
            loadingIconElem.style.display = 'block';
            generateScreenshotBtn.classList.add('btn-disabled');
            screenshotNotice.style.display = 'none';
            // make call to api
            const apiUrl = 'https://api.apiflash.com/v1/urltoimage';
            const device = template?.category ?? "Mobile";
            let deviceWidth = 0;
            switch(device) {
                case "Mobile":
                    deviceWidth = 375;
                    break;
                case "Tablet":
                case "Computer":
                    deviceWidth = 1920;
                    break;
                default:
                    deviceWidth = 1920;
            }
            const aspectRatio = template.aspectRatio.split(":").map(o => parseInt(o));
            const deviceHeight = (deviceWidth / aspectRatio[0]) * aspectRatio[1];
            const params = {
                url,
                access_key: '7970efe38c874a018df0dc9f30019dcf',
                width: deviceWidth,
                height: Math.round(deviceHeight * 1.5),
                scale_factor: 2,
                no_ads: true,
                no_cookie_banners: true,                
                scroll_page: true,
                response_type: 'json',
                quality: 90
            }
            axios.get(apiUrl, { params })
                .then((res) => {
                    // upload to uploadcare
                    let file = uploadcare.fileFrom('url', res.data.url);
                    dialogApi.addFiles([file]);
                    // remove progress indicators
                    file.done(() => {
                        loadingIconElem.style.display = 'none';
                        generateScreenshotBtn.classList.remove('btn-disabled');
                    });
                })
        }

        ReactDOM.render(
            <>
                <TabButtonIcon node={buttonNode}>
                    <symbol
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        x="5.5"
                        y="5.5"
                        id={`uploadcare--icon-${TAB_NAME}`}>
                        <path d="M19.8 1H2.2C0.9867 1 0 1.9867 0 3.2V18.6C0 19.8133 0.9867 20.8 2.2 20.8H19.8C21.0133 20.8 22 19.8133 22 18.6V3.2C22 1.9867 21.0133 1 19.8 1ZM19.8 3.2L19.8011 7.6H2.2V3.2H19.8ZM2.2 18.6V9.8H19.8011L19.8022 18.6H2.2Z" fill="fillCurrent" />
                        <path d="M13.2 4.29999H15.4V6.49999H13.2V4.29999ZM16.5 4.29999H18.7V6.49999H16.5V4.29999Z" fill="fillCurrent" />
                    </symbol>
                </TabButtonIcon>
                <div>
                    <div className="w-104 mx-auto bg-white px-4 pt-3 pb-4 rounded">
                        <form onSubmit={(e) => { e.preventDefault(); handleScreenshotClick(); }}>
                            <div className="uploadcare--text uploadcare--text_size_large uploadcare--tab__title">Create a screenshot from your site</div>
                            <label htmlFor="screenshot_url" className="block text-sm font-medium text-gray-700 text-left">Enter your website URL</label>
                            <div className="mt-1 mb-4 flex rounded">
                                <span className="inline-flex items-center px-3 rounded-l border border-gray-300 bg-gray-200 text-gray-600 text-sm">
                                    https://</span>
                                <input type="text" name="screenshot_url" id="screenshot_url" className="flex-1 min-w-0 block w-full px-3 py-2 rounded-r sm:text-sm focus:outline-none border-gray-300 border border-l-0" placeholder="example.com" />
                            </div>
                            <div id="screenshot-notice" style={{ display: 'none' }} className="text-left text-red-500 mb-3">Whoops! Please enter a valid url and try again...</div>
                            <button type="submit" id="btn-generate-screenshot" className="flex items-center mx-auto uploadcare--button uploadcare--button_size_big uploadcare--button_primary">{loadingIcon}Generate Screenshot</button>
                        </form>
                    </div>
                </div>


            </>,
            container[0]
        );
    };


    let canvasElement = null;
    if (Array.isArray(canvasElements) && canvasElements.length == 1) {
        canvasElement = canvasElements[0];
    }
    // verify template has already been selected
    const template = _.find(mockupsLibrary, {
        uid: canvasElement?.templateId,
    });
    if (template == null) {
        // TODO: navigate causing issue on build
        isBrowser && navigate('/template');
        return (null);
    }
    const templateAspectRatio = template?.aspectRatio ? template.aspectRatio : "1:1";
    const templateAspectRatioNumbers = templateAspectRatio.split(":").map(o => Number(o));
    const templateAspectRatioDivisor = Math.max(...templateAspectRatioNumbers);
    const templateAspectRatioMaxWidth = templateAspectRatioDivisor == 13 ? 2210 : 1920;
    const templateAspectWidth = (templateAspectRatioMaxWidth/ templateAspectRatioDivisor) * templateAspectRatioNumbers[0];
    const templateAspectHeight = (templateAspectRatioMaxWidth/ templateAspectRatioDivisor) * templateAspectRatioNumbers[1];
    // 
    let initialUploadArgs = null;
    if (canvasElement?.placeholder?.uuid && canvasElement?.placeholder?.originalUrl) {
        initialUploadArgs = { url: canvasElement?.placeholder?.originalUrl, uuid: canvasElement?.placeholder?.uuid };
    }
    return (
        <MockupContainer>
            <MockupHeader />
            <div className="uploadMediaWrap flex items-center flex-col flex-1 w-full max-w-screen-2xl mx-auto px-10 pt-8 mb-5 ">
                <div className="block w-full">
                    <h2 className="text-2xl font-bold font-dm mb-8">Upload your media</h2>
                </div>
                <div className="flex flex-wrap w-full">
                    <div className="flex flex-col w-full xl:w-3/4 order-1 ">
                        <div className="bg-white shadow rounded uploadcare-hidedone">
                            <MockupUploads 
                                aspectRatio={template?.aspectRatio ? template.aspectRatio : '9:16'} 
                                initialUpload={initialUploadArgs}
                                onSelect={(fileInfo) => {
                                    // use cdnUrl, and add the /-/preview if it doesn't exist
                                    let baseUrl = fileInfo.cdnUrl;
                                    if(!baseUrl.endsWith("-/preview/")) {
                                        baseUrl += "-/preview/";
                                    }
                                    const media = { url: baseUrl + '2048x2048/-/resize/2048x2048/', cdnUrl: baseUrl, uuid: fileInfo.uuid, originalUrl: fileInfo.originalUrl };
                                    setSelectedMedia(media);
                                }}
                                onDeselect={() => {
                                    setSelectedMedia(null); 
                                }}
                                onDone={(fileInfo) => {
                                    // use cdnUrl, and add the /-/preview if it doesn't exist
                                    let baseUrl = fileInfo.cdnUrl;
                                    if(!baseUrl.endsWith("-/preview/")) {
                                        baseUrl += "-/preview/";
                                    }
                                    const media = { url: baseUrl + '2048x2048/-/resize/2048x2048/', cdnUrl: baseUrl, uuid: fileInfo.uuid, originalUrl: fileInfo.originalUrl };
                                    mediaSubmit(media);
                                }}
                                tabs={'file url'}
                            />
                        </div>
                    </div>
                    <div className="flex-1 xl:pl-8 order-0 xl:order-2">
                        <div className="bg-white inline-flex shadow rounded mb-4 mx-auto py-2 px-4">
                            <div className="text-xs text-center"><span className="text-gray-500 font-semibold tracking-tight">Recommended Size:</span> {templateAspectWidth}px <span className="text-gray-600">x</span> {templateAspectHeight}px ({templateAspectRatio})</div>
                        </div>
                        <div className="hidden xl:block bg-white shadow rounded mb-4 mx-auto py-2.5 px-3">
                            <div className="flex"><FiInfo className="w-12 mt-0.5 text-gray-500"/><p className="ml-1.5 text-xs text-gray-700">More editing controls are available in the next step including colour adjustments, blur and more</p></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white shadow-top w-full">
                {/* <div className="mt-6 flex items-end justify-center text-sm py-3 flex-col max-w-screen-2xl mx-auto"> */}
                <div className="flex items-center justify-end text-sm py-3 max-w-screen-2xl mx-auto px-10">
                    <button onClick={() => { navigate('/template') }} className="py-3 px-4 transition duration-150 hover:bg-opacity-75 font-medium rounded text-gray-600 mr-3 bg-gray-200"><span className="opacity-75 mr-1">Back:</span>Select Template</button>
                    <button onClick={() => { submitUploadCare() }} className={`py-3 px-4 transition duration-150 hover:bg-indigo-600 bg-indigo-700 font-semibold rounded text-white ${!selectedMedia?.url ? 'btn-disabled' : ''}`}><span className="opacity-50 mr-1">Next:</span>Edit Mockup</button>
                </div>
            </div>
        </MockupContainer>
    )
}
export default MockupChooseMedia;